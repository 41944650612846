import React, { useEffect } from "react"
 
function Babedoo(props) {
    useEffect(() => {
        document.title = "Babedoo"
    }, []);

  return (
    <div className="App">
      <header>
        <h1 className="App-Text">
          Babedoo
        </h1>
      </header>
      <p className="App-Text">
        <a className="App-link" href="https://apps.apple.com/us/app/babedoo/id6443924805" target="_blank" rel="noopener noreferrer">Download</a> on the App Store now.
      </p>
    </div>
  )
}
 
export default Babedoo;