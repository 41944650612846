// Import the BrowserRouter, Route and Link components
import { BrowserRouter, Route } from 'react-router-dom';
import Babedoo from './components/Babedoo.js';
import Privacy from './components/Privacy.js';
import Support from './components/Support.js';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route exact path="/" component={Babedoo} />
        <Route exact path="/privacy" component={Privacy}/>
        <Route exact path="/support" component={Support}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
